import React from 'react';
import useMedia from '../../hooks/useMedia'
import HomePageMobile from "./HomePageMobile";
import HomePageDesktop from "./HomePageDesktop";
import './HomePage.scss';

function HomePage() {
    const isDesktop = useMedia(
        ['(min-width: 1200px)', '(min-width: 992px)', '(min-width: 768px)', '(min-width: 576px)', '(max-width: 575.98px)'],
        [true, true, false, false, false],
        true
    );

    return isDesktop ? <HomePageDesktop/> : <HomePageMobile/>;
}

export default HomePage;
