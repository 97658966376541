import React from 'react'
import PropTypes from 'prop-types';
import { LEADER_BOARD_SHOW_TOP_AMOUNT as SHOW_TOP_AMOUNT } from '../../../constants';

LeaderBoardTableBody.propTypes = {
    rows: PropTypes.array.isRequired,
    prepareRow: PropTypes.func.isRequired,
    showTop: PropTypes.bool,
};

function LeaderBoardTableBody({rows, prepareRow, myRiders, showMyRiders, showTop}) {
    function getConcreteRows() {
        let concreteRows = rows;
        const myRidersRows = filterRowsByMyRiders(concreteRows);

        if (myRidersRows.length) {
            concreteRows = myRidersRows;
        }

        return showTop ? filterRowsByTopPosition(concreteRows) : concreteRows;
    }

    function filterRowsByMyRiders(rows) {
        return showMyRiders ? rows.filter((row) => !!myRiders[row.original.rider.id]) : [];
    }

    function filterRowsByTopPosition(rows) {
        return rows.filter(row => row.original.race.position <= SHOW_TOP_AMOUNT);
    }

    function renderRow(row) {
        prepareRow(row);

        return (
            <tr {...row.getRowProps({className: 'lbtr'})}>
                {renderCells(row)}
            </tr>
        );
    }

    function renderCells(row) {
        return row.cells.map(renderCell);
    }

    function renderCell(cell) {
        const inSegment = cell.column.Header === cell.row.original.race.track.inSegment;

        return (
            <td {...cell.getCellProps({className: inSegment ? 'in-segment' : 'lbtd'})}>{cell.render('Cell')}</td>
        );
    }

    const tBodyMarkup = getConcreteRows().map(renderRow);

    return <tbody>{tBodyMarkup}</tbody>;
}

export default LeaderBoardTableBody;