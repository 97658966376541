import React from 'react'
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { useTable } from 'react-table'

import LeaderBoardTableHead from './LeaderBoardTableHead';
import LeaderBoardTableBody from './LeaderBoardTableBody';
import './LeaderBoardTable.scss';
// import ScrollBar from "react-perfect-scrollbar";

LeaderBoardTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.array.isRequired,
    myRiders: PropTypes.objectOf(PropTypes.bool),
    showMyRiders: PropTypes.bool,
    showTop: PropTypes.bool,
};

function LeaderBoardTable({columns, rows: data, myRiders, showMyRiders, ...props}) {
    console.log(data);
    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    });




    return (
        <div className="pex">
            <Table className="leader-board-table" borderless variant="dark">
                <LeaderBoardTableHead columns={headerGroups} />
                <LeaderBoardTableBody
                    rows={rows}
                    prepareRow={prepareRow}
                    myRiders={myRiders}
                    showMyRiders={showMyRiders}
                    showTop={props.showTop}
                    {...getTableBodyProps()}/>
            </Table>
        </div>
    );
}

export default LeaderBoardTable;