import keyBy from 'lodash/keyBy';

export function mapSegmentsDiff(dataRaceResults) {
    // Check if dataRaceResults is defined and has a property B
    if (!dataRaceResults || !Array.isArray(dataRaceResults.B) || dataRaceResults.B.length === 0) {
        return {};
    }

    const mappedRaceResults = dataRaceResults.B.map((dataRacePosition) => {
        // Ensure dataRacePosition.SR is defined and is an array before mapping
        const segments = Array.isArray(dataRacePosition.SR) ? dataRacePosition.SR.map((track) => {
            return {
                name: track.SN,
                time: track.ST,
            };
        }) : [];

        return {
            segments: keyBy(segments, 'name'),
            riderId: dataRacePosition.I,
            lapsCompleted: dataRacePosition.L,
        };
    });

    return keyBy(mappedRaceResults, 'riderId');
}

export function hasIncreasedLastTime(oldSegmentTime, newSegmentTime) {
    const oldTimeInSeconds = timeToSeconds(oldSegmentTime);
    const newSegmentTimeInSeconds = timeToSeconds(newSegmentTime);
    return oldTimeInSeconds < newSegmentTimeInSeconds;
}

function timeToSeconds(time) {
    const splittedTime = time.split(':');
    if (splittedTime.length === 1) {
        return time;
    } else {
        const minutes = Number(splittedTime[0]);
        const seconds = Number(splittedTime[1]);
        return minutes * 60 + seconds;
    }
}
