import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import AnnouncementService from "../../services/AnnouncementseService";
import AnnouncementsDesktop from "./AnnouncementsDesktop";
import './Announcements.scss';
import {
    ANNOUNCEMENTS_TIMEOUT_POLLING as TIMEOUT_POLLING,
} from './../../constants';
Announcements.propTypes = {
    isMobile: PropTypes.bool
};

Announcements.defaultProps = {
    isMobile: false
};



function Announcements({ isMobile }) {
    const [announcements, setAnnouncements] = useState([])
    const isDesktop = !isMobile;
    
    useEffect(() => {
        getAnnouncements();
        const pollingInterval = setInterval(() => getAnnouncements(), TIMEOUT_POLLING);
    
        return () => {
            clearInterval(pollingInterval);
        }
    }, [])
    
    async function getAnnouncements() {
        const announcements =  await AnnouncementService.getAnnouncements();
        setAnnouncements(announcements);
    }

    function showComponentIfWeHaveAnnouncements() {
        if (announcements && announcements.length) {
            return (
                <div className="announcements-bar-container">
                    {isDesktop ? <AnnouncementsDesktop announcements={announcements} /> : null}
                </div>
            );
        } else {
            return null;
        }
    }
    
    return showComponentIfWeHaveAnnouncements();

}

export default Announcements;