import React from 'react';
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import { BIKE_BRANDS } from './../../constants';
import HondaBikeLogo from './../../assets/images/brand_logos/bike/bike-honda.png';
import HusqvarnaBikeLogo from './../../assets/images/brand_logos/bike/bike-husqvarna.png';
import KawasakiBikeLogo from './../../assets/images/brand_logos/bike/bike-kawasaki.png';
import KTMBikeLogo from './../../assets/images/brand_logos/bike/bike-ktm.png';
import SuzukiBikeLogo from './../../assets/images/brand_logos/bike/bike-suzuki.png';
import YamahaBikeLogo from './../../assets/images/brand_logos/bike/bike-yamaha.png';
import DefaultBikeLogo from './../../assets/images/brand_logos/bike/bike-default.png';
import GasGasBikeLogo from './../../assets/images/brand_logos/bike/gasgas.png';
import BetaBikeLogo from './../../assets/images/brand_logos/bike/bike-beta.png';
import TriumphBikeLogo from './../../assets/images/brand_logos/bike/bike-triumph.png';
import CobraBikeLogo from './../../assets/images/brand_logos/bike/bike-cobra.png';

BikeIcon.propTypes = {
    brand: PropTypes.oneOf(Object.values(BIKE_BRANDS)).isRequired,
};

const bikeNameToLogoMapper = {
    [BIKE_BRANDS.HONDA]: HondaBikeLogo,
    [BIKE_BRANDS.GASGAS]: GasGasBikeLogo,
    [BIKE_BRANDS.BETA]: BetaBikeLogo,
    [BIKE_BRANDS.TRIUMPH]: TriumphBikeLogo,
    [BIKE_BRANDS.HUSQVARNA]: HusqvarnaBikeLogo,
    [BIKE_BRANDS.KAWASAKI]: KawasakiBikeLogo,
    [BIKE_BRANDS.KTM]: KTMBikeLogo,
    [BIKE_BRANDS.SUZUKI]: SuzukiBikeLogo,
    [BIKE_BRANDS.YAMAHA]: YamahaBikeLogo,
    [BIKE_BRANDS.COBRA]: CobraBikeLogo
};

function BikeIcon({ brand }) {
    const bikeLogoSrc = bikeNameToLogoMapper[brand] || DefaultBikeLogo;

    return <Image src={bikeLogoSrc} alt={brand} className="bike-logos" />
}

export default BikeIcon;