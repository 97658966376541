export function mapFromAnnouncementsDataToList(announcementsData) {
    // Check if announcementsData undefined
    if (!announcementsData) {
        // Return an empty array or handle the case as appropriate
        return [];
    }
    
    const announcementsList = announcementsData;
    return announcementsList.map((announcement) => {
        return {
            timestamp: announcement.MicrosecondsUTC,
            formattedTime: announcement.DateTimeLocalDisplay,
            message: announcement.Message
        };
    });
}
