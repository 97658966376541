import React from 'react';

import './LeaderBoard.scss';
import LeaderBoardHead from "./LeaderboardHead";
import LeaderBoardTable from "./LeaderBoardTable/LeaderBoardTable";
import PropTypes from "prop-types";

LeaderBoardDesktop.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    race: PropTypes.shape({
        flagState: PropTypes.string.isRequired,
        timeLeft: PropTypes.string,
        sessionName: PropTypes.string.isRequired,
        positions: PropTypes.arrayOf(PropTypes.object).isRequired
    }).isRequired,
    myRiders: PropTypes.objectOf(PropTypes.bool).isRequired,
    showMyRiders: PropTypes.bool.isRequired,
    handleClickShowMyRiders: PropTypes.func.isRequired,
    showTop: PropTypes.bool,
    handleClickShowTop: PropTypes.func.isRequired,
    eventWeather: PropTypes.object.isRequired,
};

LeaderBoardDesktop.defaultProps = {
    race: {
        // Other default race properties
        timeLeft: 'Loading time...', // Provide a sensible default
    }
};

function LeaderBoardDesktop({   columns, race: { flagState, timeLeft, sessionName, positions },
                                myRiders, showMyRiders, handleClickShowMyRiders,
                                showTop, handleClickShowTop, eventWeather }) {
    
        return (
        <>
            <LeaderBoardHead
                title={sessionName}
                // ADDED TO CHANGE FLAG STATE TO CHECKERED WHEN NO RACE
                flag={(flagState === 'none') ? 'checkered' : flagState}
                timeLeft={timeLeft}
                myRiders={myRiders}
                showMyRiders={showMyRiders}
                handleClickShowMyRiders={handleClickShowMyRiders}
                showTop={showTop}
                handleClickShowTop={handleClickShowTop}
                eventWeather={eventWeather}/>
            <LeaderBoardTable
                columns={columns}
                rows={positions}
                myRiders={myRiders}
                showMyRiders={showMyRiders}
                showTop={showTop}/>
        </>
    );
}

export default LeaderBoardDesktop;