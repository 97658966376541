import React from 'react';
import {Popover} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faFlagCheckered, faCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {Button, Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {faFilter } from "@fortawesome/free-solid-svg-icons";
// import EventScheduleButton from '../Schedule/EventScheduleButton';
import FlagStatus from '../common/FlagStatus/FlagStatus'
import {
    FLAG_STATUSES,
    RESULTS_PAGE_URL,
    LEADER_BOARD_SHOW_TOP_AMOUNT as SHOW_TOP_AMOUNT
} from '../../constants';

LeaderBoardHead.propTypes = {
    title: PropTypes.string.isRequired,
    flag: PropTypes.oneOf(FLAG_STATUSES),
    timeLeft: PropTypes.string.isRequired,
    myRiders: PropTypes.objectOf(PropTypes.bool).isRequired,
    showMyRiders: PropTypes.bool.isRequired,
    handleClickShowMyRiders: PropTypes.func.isRequired,
    showTop: PropTypes.bool,
    handleClickShowTop: PropTypes.func.isRequired,
    eventWeather: PropTypes.object.isRequired,
};

LeaderBoardHead.defaultProps = {
    timeLeft: 'Loading time...', // Default value if timeLeft isn't provided
};

function LeaderBoardHead({title, flag, timeLeft, myRiders, showMyRiders, handleClickShowMyRiders, showTop, handleClickShowTop, eventWeather}) {
    const showTopButtonText = showTop ? 'Show All Riders' : `Top ${SHOW_TOP_AMOUNT} Riders`;
    const showMyRidersButtonText = showMyRiders ? 'All Riders' : 'My Riders';
    const hasMyRiders = Object.values(myRiders).some(showRider => showRider);

    function renderMyRidersButtonWithTooltip() {
        let myRidersButton = renderMyRidersButton();

        // hack to show overlay on disable buttons
        myRidersButton = React.cloneElement(myRidersButton, {style: {pointerEvents: 'none'}});

        const tooltipMarkup = (
            <Tooltip>Follow some riders to select this filter</Tooltip>
        );

        return (
            <OverlayTrigger placement="bottom" overlay={tooltipMarkup}>
                {/* this span allow us to show overlay on disable buttons*/}
                <span className="d-inline-block">
                    {myRidersButton}
                </span>
            </OverlayTrigger>
        );
    }

    function renderMyRidersButton() {
        return (
            <Button className="show-top-btn" onClick={handleClickShowMyRiders} disabled={!hasMyRiders}>
                <FontAwesomeIcon icon={faFilter} size="xs"/>&nbsp;
                {showMyRidersButtonText}
            </Button>
        );
    }

    function renderWeatherDetails() {
        const { iconUrl, degreesFahrenheit, degreesCelsius, forecast, windDirection, windSpeed, humidity } = eventWeather;
        return (
            <div className="weather-details">
                <img src={iconUrl} alt="Weather icon" className="weather-icon"/>
                <span>{forecast} | </span>
                <span>{degreesFahrenheit}°F ({degreesCelsius}°C) | </span>
                <span>Wind: {windDirection} at {windSpeed} | </span>
                <span>Humidity: {humidity}%</span>
            </div>
        );
    }

    return (
        <Container fluid={true}>
            <Row className="no-gutters">
                <Col xs={2}>
                    <span className="title main-title">LEADERBOARD</span>
                </Col>
                <Col xs={10} className="text-right flag-status-container">
                    <FlagStatus flag={flag} />
                    {/* <EventScheduleButton /> */}
                    <OverlayTrigger trigger="hover" placement="auto-end" overlay={
                    <Popover className="flags-popover">
                        <Popover.Content>
                            <div className="legend-popover">
                                <div className="legend-popover-item">
                                    <FontAwesomeIcon icon={faFlagCheckered} /><span className="legend-popover-desc">Rider has taken the checkers</span>
                                </div>
                                <div className="legend-popover-item">
                                    <FontAwesomeIcon icon={faAngleUp} className="legend-green" size="lg" /><span className="legend-popover-desc">Rider has gained position(s)</span>
                                </div>
                                <div className="legend-popover-item">
                                    <FontAwesomeIcon icon={faAngleDown} className="legend-red" size="lg" /><span className="legend-popover-desc">Rider has lost position(s)</span>
                                </div>
                                <div className="legend-popover-item">
                                    <FontAwesomeIcon icon={faCircle} className="legend-green" size="sm" /><span className="legend-popover-desc">No change in position</span>
                                </div>
                                <div className="legend-popover-item">
                                    <span className="legend-green">Green</span><span className="legend-popover-desc">Rider's best for that segment.</span>
                                </div>
                                <div className="legend-popover-item">
                                    <span className="legend-red">Red</span><span className="legend-popover-desc">Overall best time for that segment.</span>
                                </div>
                            </div>
                        </Popover.Content>
                    </Popover>
                    }>
                        <div className="legend">
                            <h6 className="title">LEGEND</h6>
                        </div>
                    </OverlayTrigger>
                    <Button href={RESULTS_PAGE_URL} rel="noopener noreferrer" target="_blank" className="show-top-btn btn btn-primary" >
                Results &nbsp;
                        {/* <FontAwesomeIcon size="xs"/> */}
                    </Button>
                    <Button className="show-top-btn" onClick={handleClickShowTop}>{showTopButtonText}</Button>
                    {
                        hasMyRiders
                            ? renderMyRidersButton()
                            : renderMyRidersButtonWithTooltip()
                    }
                </Col>
            </Row>
            <Row className="no-gutters">
                <div className="subhead-container">
                    <h6 className="sub-title">{title} </h6>
                    <h6>|</h6>
                    <h6 className="time-left">Time To Go: {timeLeft} </h6>
                </div>
                <div className="subhead-container">
                    {renderWeatherDetails()}
                </div>
            </Row>
        </Container>
    )
}

export default LeaderBoardHead;