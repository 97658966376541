import React from 'react';
import { Popover } from "react-bootstrap";
import Flag from "./Flag";

const colorDescriptionFlags = [{
    color: 'none',
    description: 'Track is cold'
}, {
    color: 'purple',
    description: 'Warm Session'
}, {
    color: 'green',
    description: 'Race Underway'
}, {
    color: 'red',
    description: 'Race Stopped'
}, {
    color: 'yellow',
    description: 'Race Caution'
}, {
    color: 'white',
    description: 'Last Lap'
}, {
    color: 'checkered',
    description: 'Race Finish'
}];

const FlagPopover = React.forwardRef((props, ref) => (
    <Popover ref={ref} className="flags-popover" {...props}>
        <Popover.Title as="h4">Flag Status:</Popover.Title>
        <Popover.Content>
            {colorDescriptionFlags.map(({ color, description }) => (
                <div key={color}>
                    <Flag className="popover-flag" color={color} /><span>{description}</span>
                </div>
            ))}
        </Popover.Content>
    </Popover>
));

// Make sure to display a custom name in DevTools, if needed
// This is optional and only for display purposes
FlagPopover.displayName = 'FlagPopover';

export default FlagPopover;
