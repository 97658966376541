import React, { useState } from 'react';
import {Col, Container, Row} from "react-bootstrap";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import LeaderBoard from "../../components/LeaderBoard/LeaderBoardContainer";
import Announcements from "../../components/Announcements/Announcements";
// import Collapsible from "../../components/common/Collapsible/Collapsible";
// import SocialFeed from "../../components/SocialFeed/SocialFeed";

import RaceService from "../../services/RaceService";
import AnnouncementService from "../../services/AnnouncementseService";
// import TwitterService from "../../services/TwitterService";

function HomePageDesktop() {
    // const initialSFColumns = 2;
    // const [SFColumns, setSFColumns] = useState(initialSFColumns);

    // function onSocialFeedCollapsed(isOpen) {
    //     const newSFColumns = isOpen ? initialSFColumns : 0;

    //     setSFColumns(newSFColumns);
    // }

    // const collapseText = (
    //     <span>Collapse &nbsp; <FontAwesomeIcon icon={faChevronRight} className={"ml-2"} /> &nbsp; Social Feed</span>
    // );

    // const expandText = (
    //     <span>Expand &nbsp; <FontAwesomeIcon icon={faChevronLeft} className={"ml-2"} /> &nbsp; Social Feed</span>
    // );

    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // let socialCheck  = params.get('social');

    var leaderBoardRender;

    leaderBoardRender = (
        <Container fluid={true} className="home-page-desktop-container">
            <Row className="raceboard">
                <Col>
                    <LeaderBoard RaceService={RaceService} />
                </Col>
            </Row>
            <Row className="no-gutters announcements" >
                <Col>
                    <Announcements AnnouncementService={AnnouncementService} />
                </Col>
            </Row>
        </Container>
    )

    // if(socialCheck === 'hide') {
    //     leaderBoardRender = (
    //         <Container fluid={true} className="home-page-desktop-container">
    //             <Row className="raceboard">
    //                 <Col>
    //                     <LeaderBoard RaceService={RaceService} />
    //                 </Col>
    //             </Row>
    //             <Row className="no-gutters announcements" >
    //                 <Col>
    //                     <Announcements AnnouncementService={AnnouncementService} />
    //                 </Col>
    //             </Row>
    //         </Container>
    //     )
    // }
    // else {
    //     leaderBoardRender = (
    //         <Container fluid={true} className="home-page-desktop-container">      
    //             <Row className="no-gutters">
    //                 <Col lg={12 - SFColumns}>
    //                     <Row className="raceboard">
    //                         <Col>
    //                             <LeaderBoard RaceService={RaceService} />
    //                         </Col>
    //                     </Row>
    //                     <Row className="no-gutters announcements" >
    //                         <Col>
    //                             <Announcements AnnouncementService={AnnouncementService} />
    //                         </Col>
    //                     </Row>
    //                 </Col>
    //                 <Col lg={SFColumns} className="collapsible-social-feed-container">
    //                 <Collapsible handleClickToggle={onSocialFeedCollapsed} collapseText={collapseText} expandText={expandText}>
    //                         <SocialFeed TwitterService={TwitterService} />
    //                     </Collapsible>
    //                 </Col>
    //             </Row>
    //         </Container>
    //     )
    // }

    return (   
        leaderBoardRender
    );
}

export default HomePageDesktop;
