import { FLAG_STATUSES } from './../../constants';

const mapFlag = FLAG_STATUSES;

function formatTime(seconds) {
  // Check if seconds is negative
  if (seconds < 0) {
    // Handle negative seconds
    return formatNegativeTime(Math.abs(seconds));
  } else {
    // Use existing logic for positive seconds with whole number seconds
    const pad = (num) => num.toString().padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60); // Use Math.floor to discard decimals
    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  }
}

function formatNegativeTime(seconds) {
  // Implement formatting for negative time with whole number seconds
  const pad = (num) => num.toString().padStart(2, '0');
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60); // Use Math.floor to discard decimals
  return `-${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
}

export default function mapFromRaceData(raceClock) {
    return {
        flagState: mapFlag[raceClock.FlagType],
        timeLeft: formatTime(raceClock.Remaining) // Convert to HH:MM:SS format
    };
}
