import React from 'react';
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import FlagStatus from "../common/FlagStatus/FlagStatus";
import LeaderBoardTable from "./LeaderBoardTable/LeaderBoardTable";
import Announcements from "../Announcements/Announcements";
import AnnouncementService from "../../services/AnnouncementseService";

LeaderBoardMobile.propTypes = {
    race: PropTypes.shape({
        sessionName: PropTypes.string.isRequired,
        positions: PropTypes.arrayOf(PropTypes.object).isRequired,
        timeLeft: PropTypes.string.isRequired
    }).isRequired,

    columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

function LeaderBoardMobile({ race: { flagState, sessionName, positions, timeLeft }, columns }) {

    console.log(columns)
    return (
        <>
            <Container fluid={true}>
                <Row>
                    <Col xs={6}>
                        <h6 className="sub-title">{sessionName}</h6>
                        <h6 className="time-left">Time To Go: {timeLeft} </h6>
                    </Col>
                    <Col xs={6} className="text-right">
                        <FlagStatus flag={(flagState === 'none') ? 'checkered' : flagState} showInfo={false}/>
                    </Col>
                </Row>
            </Container>
            <LeaderBoardTable columns={columns} rows={positions} showTop={false} />
            <Announcements AnnouncementService={AnnouncementService} />
        </>
    );
}

export default LeaderBoardMobile;