import mapFromRaceData from './raceDataMapper';
// import PositionsCache from '../PositionsCache';

export function mapFromDataRaceToRace(dataRacedata, dataRaceClock, dataRaceResults, dataRaceWeather) {
    return buildRaceAndPositions(dataRacedata, dataRaceClock, dataRaceResults, dataRaceWeather);
}

function buildRaceAndPositions(dataRaceData, dataRaceClock, dataRaceResults, dataRaceWeather) {
    /**
     * @typedef {Object} WeatherData
     * @property {string} iconUrl - URL of the weather icon
     * @property {number} degreesFahrenheit - Temperature in degrees Fahrenheit
     * @property {number} degreesCelsius - Temperature in degrees Celsius
     * @property {string} forecast - Weather forecast description
     * @property {string} windDirection - Direction of the wind
     * @property {string} windSpeed - Speed of the wind
     * @property {number} humidity - Humidity percentage
     */

    /** @type {WeatherData} */
    const sessionWeather = {
        iconUrl: dataRaceWeather.IconURL,
        degreesFahrenheit: dataRaceWeather.TemperatureDegreesFahrenheit,
        degreesCelsius: dataRaceWeather.TemperatureDegreesCelsius,
        forecast: dataRaceWeather.Forecast,
        windDirection: dataRaceWeather.WindDirection,
        windSpeed: dataRaceWeather.WindSpeed,
        humidity: dataRaceWeather.HumidityPercentage
    };

    //console.log(buildPositions(dataRaceResults, dataRaceData));

    return {
        ...mapFromRaceData(dataRaceClock),
        trackSegments: trackSegments(dataRaceData.SectorNames.sort((a, b) => a.SectorNumber - b.SectorNumber)),
        sessionName: `${dataRaceData.EventName} | ${dataRaceData.ClassName}`,
        sessionWeather: sessionWeather,
        totalTrackSegments: dataRaceData.NumberOfSectors,
        dateTime: null,
        holeShot: null,
        positions: buildPositions(dataRaceResults, dataRaceData)
    }
}

function trackSegments(sectorNames) {
    return Array.from(sectorNames, (element) => {
        return { name: `${element.SectorName}` };
    });


}

function buildPositions(dataRaceResults, dataRaceData) {
    const totalSegments = dataRaceData.NumberOfSectors;
    const sectorNameMap = new Map(dataRaceData.SectorNames.map(item => [item.SectorNumber, item.SectorName]));

    if (!dataRaceResults || !Array.isArray(dataRaceResults)) {
        return [];
    }

    const positions = dataRaceResults.map(dataRacePosition => {
        let status = 'Active'; // Default status
        if (dataRacePosition.IsBroken) {
            status = 'OUT';
        } else if (dataRacePosition.IsDisqualified) {
            status = 'DQ';
        }

        let raceMarker = 0;
        if (dataRacePosition.IsPitting) {
            raceMarker = 7;
        } else if (dataRacePosition.IsComplete) {
            raceMarker = 6;
        } else if (dataRacePosition.PositionChangeSinceLastLap < 0) {
            raceMarker = 5;
        } else if (dataRacePosition.PositionChangeSinceLastLap > 0) {
            raceMarker = 4;
        }

        const defaults = {
            FastestLap: '--.---',
            LapTime: '--.---',
            FastestLapNumber: '',
            Manufacturer: ''
        };
        Object.keys(defaults).forEach(key => {
            if (!dataRacePosition[key] || dataRacePosition[key] === null) {
                dataRacePosition[key] = defaults[key];
            }
        });

        let processedSectors = dataRacePosition.LatestSectors || [];
        // Ensure all sectors are included from 1 to totalSegments
        const sectorsMap = new Map(processedSectors.map(s => [s.SectorNumber, s]));
        processedSectors = [];
        for (let i = 1; i <= totalSegments; i++) {
            if (sectorsMap.has(i)) {
                processedSectors.push(sectorsMap.get(i));
            } else {
                processedSectors.push({
                    SectorNumber: i,
                    SectorTimeSeconds: '--.---',
                    IsFastestSectorNumber: false,
                    IsFastestSectorNumberOverall: false,
                    DriverLID: null,
                    LapNumberStart: null,
                    LapNumberFinish: null
                });
            }
        }

        const bestLapTimeInSeconds = dataRacePosition.FastestLap && dataRacePosition.FastestLap !== '--.---'
            ? parseFloat(dataRacePosition.FastestLap)
            : null;

        return {
            race: {
                position: dataRacePosition.Position,
                gap: dataRacePosition.DifferenceBehindPrecedingDisplay || '--.---',
                diff: dataRacePosition.DifferenceBehindLeaderDisplay || '--.---',
                lapsCompleted: dataRacePosition.CompletedLaps,
                lastLapTime: dataRacePosition.LapTime,
                bestLapTime: dataRacePosition.FastestLap,
                bestLap: dataRacePosition.FastestLapNumber,
                raceMarker: raceMarker,
                bestLapTimeInSeconds: bestLapTimeInSeconds,
                track: {
                    lastSegmentCompleted: sectorNameMap.get(dataRacePosition.CompletedSectorNumber) || `S${dataRacePosition.CompletedSectorNumber}`,
                    segments: processedSectors.map(track => ({
                        name: sectorNameMap.get(track.SectorNumber) || `S${track.SectorNumber}`,
                        time: track.SectorTimeSeconds,
                        timeIndicator: track.IsFastestSectorNumber ? 1 : track.IsFastestSectorNumberOverall ? 2 : 0,
                        improvedLastTime: null,
                        increasedLastTime: null,
                    }))
                },
            },
            rider: {
                id: dataRacePosition.DriverLID,
                name: `${dataRacePosition.FirstName} ${dataRacePosition.LastName}`,
                number: dataRacePosition.BikeNumber,
                vehicle: {
                    name: dataRacePosition.Manufacturer,
                    brand: dataRacePosition.Manufacturer.toLowerCase()
                },
                status: status,
                hometown: dataRacePosition.Hometown || 'N/A',
                team: dataRacePosition.TeamName
            },
        };
    });

    const overallBestLapTime = positions.reduce((min, position) => {
        return position.race.bestLapTimeInSeconds !== null 
               ? Math.min(min, position.race.bestLapTimeInSeconds) 
               : min;
    }, Infinity);

    return positions.map(position => ({
        ...position,
        race: {
            ...position.race,
            isBestOverallLap: position.race.bestLapTimeInSeconds === overallBestLapTime,
        },
    }));
}

