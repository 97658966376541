import keyBy from 'lodash/keyBy';

export function mapRaceLaptimes(rawLaptimes) {
    const drivers = rawLaptimes.B.map(driver => {
        const laps = driver.C;
        return {
            driverNumber: driver.A,
            laps: mapLapsForDriver(laps),
        };
    })
    return keyBy(drivers, 'driverNumber');
}

function mapLapsForDriver(laps) {
    return laps.map(lap => ({
        number: lap.A,
        position: lap.P,
        time: lap.D,
        diff: lap.LD,
        improvedDiff: lap.B === 4,
        increasedDiff: lap.B === 5,
    }));
}
