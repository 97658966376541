import axios from 'axios';
import { mapFromAnnouncementsDataToList } from "../services/mappers/announcementsMapper";

const REACT_APP_API_ANNOUNCE = process.env.REACT_APP_API_ANNOUNCE;

const announcementService = {
    getAnnouncements: async function() {
        const announcementsData = await getAnnouncementsData();
        return mapFromAnnouncementsDataToList(announcementsData);
    },
};

function addCacheBuster(url) {
    const timestamp = new Date().getTime(); // Get current timestamp
    return `${url}?t=${timestamp}`;
}

async function getAnnouncementsData() {
    //const urlWithCacheBuster = addCacheBuster(REACT_APP_API_ANNOUNCE);
    const response = await axios.get(REACT_APP_API_ANNOUNCE);
    return response.data;
}

export default announcementService;
