import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Announcement from "./Announcement/Announcement"
import ScrollBar from "react-perfect-scrollbar";

function AnnouncementsDesktop({announcements}) {
    
    function renderAnnouncements() {
        return (
            announcements.map((announcement, index) => {
                return <Announcement key={index} announcement={announcement}/>
            })
        )
    }
    return (
        <div className="announcements-bar-desktop-container">
            <span className="title">ANNOUNCEMENTS:</span>
            <Container fluid={true} className="announcements-list-container" fixed="bottom">
                <Row className="no-gutters">
                    <Col className="announcements-list">
                        <ScrollBar>
                            {renderAnnouncements()}
                        </ScrollBar>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default AnnouncementsDesktop;