import React, { useState } from 'react';
import Tabs from "react-bootstrap/Tabs";
import { Tab } from "react-bootstrap";
import RaceService from "../../services/RaceService";
import LeaderBoard from "../../components/LeaderBoard/LeaderBoardContainer";

const LEADERBOARD = 'leaderboard';
const RESULTS = 'results';

function HomePageMobile() {
    const [activeTab, setActiveTab] = useState(LEADERBOARD);

    const onSelect = (key) => {
        if (key === RESULTS) {
            // Open the results URL in a new tab
            window.open("https://scoring.supermotocross.com/results/", "_blank");
        } else {
            setActiveTab(key);  // Only change the tab if it's not "results"
        }
    };

    return (
        <div className="home-page-mobile-container">
            <Tabs activeKey={activeTab} onSelect={onSelect}>
                <Tab eventKey={LEADERBOARD} title="LEADERBOARD" className="justify-content-center">
                    <LeaderBoard RaceService={RaceService} isMobile={true} />
                </Tab>
                <Tab eventKey={RESULTS} title="RESULTS" className="justify-content-center">
                    {/* Empty tab since it opens an external link */}
                </Tab>
            </Tabs>
        </div>
    );
}

export default HomePageMobile;
