import React, { useState, useEffect, useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from "prop-types";

import LeaderBoardMobile from "./LeaderBoardMobile";
import LeaderBoardDesktop from "./LeaderBoardDesktop";
import buildColumns from './LeaderBoardTable/LeaderBoardTableColumnsBuilder'

import { LEADER_BOARD_TIMEOUT_POLLING as TIMEOUT_POLLING } from './../../constants';
import './LeaderBoard.scss';

LeaderBoardContainer.propTypes = {
    RaceService: PropTypes.shape({
        getRaceAndPositions: PropTypes.func.isRequired
    }).isRequired,
    isMobile: PropTypes.bool
};

function LeaderBoardContainer({ RaceService, isMobile = false }) {
    console.log('RaceService:',RaceService)
    const [race, setRace] = useState({
        dateTime: null,
        positions: [],
        sessionName: '',
        trackSegments: []
    });
    const driverCalloutState = useState(null);
    const [isDriverCalloutVisible] = driverCalloutState;
    const [myRiders, setMyRiders] = useState({});
    const [showTop, setShowTop] = useState(false);

    const showMyRidersInitial = false;
    const [showMyRiders, setShowMyRiders] = useState(showMyRidersInitial);

    const getRacePositions = useCallback(async () => {
        try {
            const race = await RaceService.getRaceAndPositions();

            // RACE CONTAINS FLAGSTATE, HOLESHOT, POSITIONS, SEGMENT DATA
            // console.log(race)

            setRace(race);
        } catch (e) {
            console.log(e)
        }
    }, [RaceService]);

    useEffect(() => {
        getRacePositions();
        let pollingInterval = null;
        if (!isDriverCalloutVisible) {
            pollingInterval = setInterval(() => getRacePositions(), TIMEOUT_POLLING);
        } else {
            clearInterval(pollingInterval);
        }

        return () => {
            clearInterval(pollingInterval);
        }
    }, [isDriverCalloutVisible, getRacePositions]);

    useEffect(() => {
        // when my riders change, we want to set showMyRiders to the initial state
        setShowMyRiders((prevShowMyRiders) => {
            if (hasRiders(myRiders)) {
                return prevShowMyRiders;
            } else {
                return showMyRidersInitial;
            }
        });
    }, [myRiders, showMyRidersInitial]);

    function handleClickShowTop() {
        setShowTop(prevShowTop => {
            if (prevShowTop) {
                return false;
            }

            return true;
        });

        // this filter overrides the show my riders
        // to the default state
        setShowMyRiders(false);
    }

    function handleClickShowMyRiders() {
        setShowMyRiders(prevShowMyRiders => {
            const hasMyRiders = hasRiders(myRiders);

            // we want to change this state only when the user has riders
            return hasMyRiders && !prevShowMyRiders;
        });
    }

    function handleClickFollowRider(riderId) {
        setMyRiders((prevMyRiders) => ({
            ...prevMyRiders,
            [riderId]: !prevMyRiders[riderId],
        }));
    }

    function hasRiders(myRiders) {
        return Object.values(myRiders).some(showRider => showRider);
    }

    // is mobile has been set to false to enable horizontal scrolling on mobile this is a temporary fix
    const columns = buildColumns({
        isMobile: false,
        race: race,
        myRiders,
        driverCalloutState: driverCalloutState,
        handleClickFollowRider
    });

    if (!race.sessionName) {
        return <Spinner animation="border" variant="light" />
    }

    const enhancedRace = {
        ...race,
        flagState: race.flagState || 'none'
    };

    const eventWeather = race.sessionWeather;

    return (
        <div className="leader-board">
            {
                isMobile
                    ? <LeaderBoardMobile
                        columns={columns}
                        race={enhancedRace} />
                    : <LeaderBoardDesktop
                        columns={columns}
                        race={enhancedRace}
                        myRiders={myRiders}
                        showMyRiders={showMyRiders}
                        handleClickShowMyRiders={handleClickShowMyRiders}
                        showTop={showTop}
                        handleClickShowTop={handleClickShowTop}
                        eventWeather={eventWeather} />
            }
        </div>
    );
}

export default LeaderBoardContainer;