import React from 'react';
import BikeIcon from "../../common/BikeIcon";
import { Form } from "react-bootstrap";
// import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faFlagCheckered, faCircle } from '@fortawesome/free-solid-svg-icons';
import DriverCalloutBox from '../DriverCalloutBox/DriverCalloutBox';

const MOBILE_HEADERS = ['Pos', '#', 'Name', 'Laps', 'Gap', 'Diff', 'Last'];
// const MOMENT_PARSE_STR_FROM_MINUTES = 'm:ss.SSS';
// const MOMENT_PARSE_STR_FROM_SECONDS = 'ss.SSS';
// const ZERO_TIME = '0.000';

export default function buildColumns({ isMobile, race, myRiders, driverCalloutState, handleClickFollowRider }) {
    function showColumn() {
        if (!isMobile) {
            return true;
        }

        return MOBILE_HEADERS.findIndex(mobileHeaderName => mobileHeaderName === this.Header) !== -1;
    }

    function formatTime(secondsFloat) {
        if (typeof secondsFloat === 'string' || secondsFloat instanceof String) {
            return secondsFloat;
        }

        const pad = (num, size = 2) => num.toString().padStart(size, '0');
        const minutes = Math.floor(secondsFloat / 60);
        const seconds = Math.floor(secondsFloat % 60);
        const milliseconds = Math.round((secondsFloat % 1) * 1000); // Get the fractional part and convert to milliseconds
    
        // Only include minutes in the output if there are any
        const minutesPart = minutes > 0 ? `${minutes}:` : '';
        
        return `${minutesPart}${pad(seconds)}.${pad(milliseconds, 3)}`;
    }       

    function buildSegmentColumns() {
        const trackSegmentsNames = race.trackSegments.map(trackSegment => trackSegment.name);

        return trackSegmentsNames.map((trackSegmentsName, index) => {
            return {
                Header: trackSegmentsName,
                accessor: `race.track.segments[${index}].time`,
                show: showColumn,
                Cell: ({ row: { original } }) => {
                    const segment = original.race.track.segments[index];
                    const time = segment?.time ?? '--.---'; // Fallback to '--.---' if time is undefined
                    const timeIndicator = segment?.timeIndicator;
                
                    if (timeIndicator === 1) {
                        // personal best time
                        return (
                            <span className="legend-green">{time}</span>
                        );
                    } else if (timeIndicator === 2) {
                        // overall best time
                        return (
                            <span className="legend-red">{time}</span>
                        );
                    }
                
                    return (
                        <span className="legend-none">{time}</span>
                    );
                }
            }
        });
    }

    return [{
        Header: 'Id',
        accessor: 'rider.id',
        show: false,
    }, {
        Header: 'Pos',
        accessor: 'race.position',
        show: showColumn
    }, {
        Header: '#',
        accessor: 'rider.number',
        show: showColumn
    }, {
        Header: 'Name',
        accessor: 'rider.name',
        show: showColumn,
        Cell: ({ row: { original } }) => <DriverCalloutBox driver={original.rider} driverCalloutState={driverCalloutState} race={original.race} isMobile={isMobile} />
    }, {
        Header: 'Bike',
        accessor: 'rider.vehicle.brand',
        show: showColumn,
        Cell: ({ row: { original } }) => <BikeIcon brand={original.rider.vehicle.brand} />
    }, {
        Header: 'Laps',
        accessor: 'race.lapsCompleted',
        show: showColumn
    }, {
        Header: 'Gap',
        accessor: 'race.gap',
        show: showColumn
    }, {
        Header: 'Diff',
        accessor: 'race.diff',
        show: showColumn
    }, {
        Header: 'Last',
        accessor: 'race.lastLapTime',
        show: showColumn,
        Cell: ({ row: { original } }) => {
            const bestLapTime = original.race.bestLapTime;
            let lastLapTime = original.race.lastLapTime;
            let isRiderBest = false;

            if (bestLapTime === lastLapTime && lastLapTime !== '0.000' && lastLapTime !== '--.---') {
                isRiderBest = true;
            }

            lastLapTime = formatTime(lastLapTime);

            return isRiderBest ? <span className="min-best-lap">{lastLapTime}</span> : lastLapTime;
        }
    }, {
        Header: 'Best',
        accessor: 'race.bestLapTime',
        show: showColumn,
        Cell: ({ row: { original } }) => {
            let isBestLapTime = original.race.isBestOverallLap;
            let bestLapTime = original.race.bestLapTime;
            if (typeof bestLapTime === 'string' || bestLapTime instanceof String) {
                if (bestLapTime.includes('--') || bestLapTime.includes("00.000")) {
                    isBestLapTime = false;
                }
            }
            if (bestLapTime === '0.000') {
                isBestLapTime = false;
            }
            bestLapTime = formatTime(bestLapTime);
            return isBestLapTime ? <span className="best-lap-overall">{bestLapTime}</span> : bestLapTime;
        }
    }, {
        Header: 'In',
        accessor: 'race.bestLap',
        show: showColumn,
    }, {
        Header: 'Status',
        accessor: 'rider.status',
        show: showColumn,
    }, {
        Header: '',
        accessor: 'race.raceMarker',
        show: showColumn,
        Cell: ({ row: { original } }) => {
            if (original.race.raceMarker === 6) {
                // the rider is finished
                return <FontAwesomeIcon icon={faFlagCheckered} />
            } else if (original.race.raceMarker === 5) {
                // the rider lost position on previous lap
                return <FontAwesomeIcon icon={faAngleDown} className="lost-position" size="lg" />
            } else if (original.race.raceMarker === 4) {
                // the rider gained position on previous lap
                return <FontAwesomeIcon icon={faAngleUp} className="gained-position" size="lg" />
            } else if (original.race.raceMarker === 0 && original.race.track.inSegment === 'S1') {
                // rider is in first segment and no change
                return <FontAwesomeIcon icon={faCircle} className="gained-position" size="sm" />
            }

            return ''
        }
    }, {
        Header: 'Hometown',
        accessor: 'rider.hometown',
        show: showColumn,
    },
    ...buildSegmentColumns(), {
        Header: 'Follow',
        accessor: 'race.follow',
        show: showColumn,
        Cell: ({ row: { values } }) => {
            const riderId = values['rider.id'];

            function onChange(_evt) {
                handleClickFollowRider(riderId);
            }

            return <Form.Check
                custom
                inline
                label=""
                type={'checkbox'}
                checked={!!myRiders[riderId]}
                onChange={onChange}
                id={`follow-rider-${riderId}`}
            />
        }
    }];
}
