export const FLAG_STATUSES = ['none', 'green', 'yellow', 'red', 'purple', 'white', 'checkered'];

export const RESULTS_PAGE_URL = 'https://scoring.supermotocross.com/results/';

export const BIKE_BRANDS = {
    HONDA: 'honda',
    HUSQVARNA: 'husqvarna',
    KAWASAKI: 'kawasaki',
    KTM: 'ktm',
    SUZUKI: 'suzuki',
    YAMAHA: 'yamaha',
    GASGAS: 'gasgas',
    BETA: 'beta',
    TRIUMPH: 'triumph',
    COBRA: 'cobra',
};

export const DRIVER_HEADSHOT_URL = (driverId) => {
    return `https://results.amaproracing.com/assets/headshots/${driverId}@2x.jpg`
}

// LEADER_BOARD
export const LEADER_BOARD_SHOW_TOP_AMOUNT = 10;
export const LEADER_BOARD_TIMEOUT_POLLING = 2000;
// ANNOUNCEMENTS
export const ANNOUNCEMENTS_TIMEOUT_POLLING = 15000;