import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import './LapsTable.scss'

function DriverLapsTable({ laptimes, bestLapNumber }) {
    function renderRows() {
        var lapData = laptimes.filter(({ number, position, time, diff, improvedDiff, increasedDiff }) => {

            // console.log('Number:', number);
            // console.log('Position:', position);
            // console.log('Time:', time);
            // console.log('Diff:', diff);
            // console.log('Improved Diff:', improvedDiff);
            // console.log('Increased Diff:', increasedDiff);


            if (number === 0) {
                return false; // skip
            }
            return true;
        }).map(({ number, position, time, diff, improvedDiff, increasedDiff }) => {

            const isBestTime = number === bestLapNumber;
            if (number === 1) {
                time = '--.---';
            }
            return (
                <tr key={number}>
                    <td>{number}</td>
                    <td>{position}</td>
                    <td className={isBestTime ? 'best-time' : ''}>{time}</td>
                    <td>{diff}</td>
                    <td className="diff-arrow">
                        {improvedDiff && <FontAwesomeIcon icon={faAngleUp} className="improved" size="sm" />}
                        {increasedDiff && <FontAwesomeIcon icon={faAngleDown} className="increased" size="sm" />}
                    </td>
                </tr>
            )
        })
        return lapData;
    }

    return (
        <Row>
            <Col sm={6}>
                <Table className="driver-callout-laps-table" borderless variant="dark">
                    <thead>
                        <tr>
                            <th>Lap</th>
                            <th>Pos</th>
                            <th>Lap Time</th>
                            <th>Diff</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default DriverLapsTable;