import React from 'react';
import PropTypes from 'prop-types';

LeaderBoardTableHead.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired
};

function LeaderBoardTableHead({ columns }) {
    const tHeadMarkup = columns.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
            </tr>
        ));

    return <thead>{tHeadMarkup}</thead>;
}

export default LeaderBoardTableHead;