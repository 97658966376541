import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Overlay, Popover, Container, Row, Col, Image, Button } from 'react-bootstrap';
import minBy from 'lodash/fp/minBy';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';

import DriverLapsTable from './LapsTable/LapsTable';
import './DriverCalloutBox.scss';
import DefaultHeadShotImage from '../../../assets/images/headshot_placeholder.png';
import { DRIVER_HEADSHOT_URL } from '../../../constants';
import RaceService from '../../../services/RaceService'

function DriverCalloutBox({ driver, race, driverCalloutState, isMobile }) {
    const [show, setShow] = useState(false);
    const [laptimes, setLaptimes] = useState([]);
    const [bestLapTime, setBestLapTime] = useState(0);
    const [bestLapNumber, setBestLapNumber] = useState(0);
    const [currentlyVisibleDriverCallout, setCurrentlyVisibleDriverCallout] = driverCalloutState;
    const [headshotUrl, setHeadShotUrl] = useState(DRIVER_HEADSHOT_URL(driver.id))
    const target = useRef(null);

    const getBestLap = useCallback(laptimes => {
        return flow(
            filter(lap => lap.time !== '--.---' && lap.time > 0),
            minBy(lap => lap.time)
        )(laptimes)
    }, []);

    useEffect(() => {
        let isMounted = true; // Flag to track the mounted state of the component
    
        async function setRaceLaptimes() {
            try {
                const laptimes = await RaceService.getRaceLaptimesForDriver(driver.number);
                if (isMounted) { // Check if the component is still mounted before setting state
                    if (laptimes) {
                        setLaptimes(laptimes.laps);
                        const bestLap = getBestLap(laptimes.laps);
                        if (bestLap) {
                            setBestLapTime(bestLap.time);
                            setBestLapNumber(bestLap.number);
                        }
                    }
                    setShow(true); // Moved inside isMounted check
                }
            } catch (error) {
                console.error(error);
            }
        }
    
        if (currentlyVisibleDriverCallout === driver.id) {
            setRaceLaptimes();
        }
    
        return () => {
            isMounted = false; // Set the flag to false when the component unmounts
        };
    }, [currentlyVisibleDriverCallout, driver, getBestLap]);    

    const handleClick = event => {
        setShow(!show);
        setCurrentlyVisibleDriverCallout(driver.id);
    };

    function onHide() {
        setCurrentlyVisibleDriverCallout(null);
        setShow(false);
    }

    function handleCloseButton() {
        onHide()
    }

    function handleHeadshotError() {
        setHeadShotUrl(DefaultHeadShotImage);
    }

    function placementAcordingToDevice() {
        return isMobile ? 'bottom' : 'right'
    }

    if (driver) {
        return (
            <>
                <span ref={target} className="callout-trigger" onClick={handleClick}>{driver.name}</span>
                <Overlay target={target.current} show={show} placement={placementAcordingToDevice()}>
                    <Popover className="driver-callout-box">
                        <Popover.Content>
                            <Container bsPrefix="callout-container" fluid>
                                <Button className="close-callout" onClick={handleCloseButton}/>
                                <Row className="no-gutters">
                                    <Col className="driver-headshot" xs={3} md={4}>
                                        <Image src={headshotUrl} onError={handleHeadshotError} alt="alt" fluid/>
                                    </Col>
                                    <Col className="driver-details" xs={9} md={8}>
                                        <p>From: {driver.hometown}</p>
                                        <p>No: {driver.number}</p>
                                        <p>Bike: {driver.vehicle.name}</p>
                                        <p>Team: {driver.team}</p>
                                        <p>Status: {driver.status}</p>
                                        <p>Best: {bestLapTime}</p>
                                        <p>In Lap: {race.bestLap}</p>
                                    </Col>
                                </Row>
                                <DriverLapsTable laptimes={laptimes} bestLapNumber={bestLapNumber} />
                            </Container>
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </>
    );
    }
    return null
}

export default DriverCalloutBox;
