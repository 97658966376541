import React from 'react';
import PropTypes from "prop-types";
import {FLAG_STATUSES} from "../../constants";

Flag.propTypes = {
    color: PropTypes.oneOf(FLAG_STATUSES).isRequired,
};

function Flag({ color }) {
    return <div className={`flag ${color}`} />;
}

export default Flag;