import get from 'lodash/get';
import { mapSegmentsDiff, hasIncreasedLastTime } from './mappers/segmentsDiffMapper';

class PositionsCache {
    constructor() {
        this.positions = {};
        this.increasedLastTimes = {};
    }

    setPositions(newPositions) {
        try {
            const newPositionsMapped = mapSegmentsDiff(newPositions);
            this.updateIncreasedLastTimes(newPositionsMapped);
            this.positions = newPositionsMapped;
        } catch (error) {
            this.initializePositions(newPositions);
        }
    }

    updateIncreasedLastTimes(newPositionsMapped) {
        Object.keys(this.positions).forEach(riderId => {
            Object.keys(this.positions[riderId].segments).forEach(segmentName => {
                this.checkAndUpdateSegmentIncreasedLastTime(riderId, segmentName, newPositionsMapped)
            });
        });
    }

    initializePositions(positions) {
        this.positions = mapSegmentsDiff(positions);
        this.initializeIncreasedLastTimes();
    }

    initializeIncreasedLastTimes() {
        Object.keys(this.positions).forEach(riderId => {
            this.initializeRiderIncreasedLastTime(riderId);
        });
    }

    initializeRiderIncreasedLastTime(riderId) {
        this.increasedLastTimes[riderId] = {};

        const segments = this.positions[riderId].segments;
        Object.keys(segments).forEach(segmentName => {
            this.increasedLastTimes[riderId][segmentName] = false;
        });
    }

    checkAndUpdateSegmentIncreasedLastTime(riderId, segmentName, newPositionsMapped) {
        const oldTime = this.positions[riderId].segments[segmentName].time;
        const newTime = newPositionsMapped[riderId].segments[segmentName].time;

        if (oldTime !== newTime) {
            if (hasIncreasedLastTime(oldTime, newTime)) {
                this.increasedLastTimes[riderId][segmentName] = true;
            } else {
                this.increasedLastTimes[riderId][segmentName] = false;
            }
        }
    }

    getIncreasedLastTime(riderId, segmentName) {
        return get(this.increasedLastTimes, `${riderId}.${segmentName}`, false);
    }
}

export default new PositionsCache();
