import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from "react-bootstrap";
import FlagPopover from "../FlagPopover";
import Flag from "../Flag";

import {
    FLAG_STATUSES,
} from '../../../constants';

FlagStatus.propTypes = {
    flag: PropTypes.oneOf(FLAG_STATUSES).isRequired, // Ensure this matches the expected prop type for flag
    showInfo: PropTypes.bool,
};

FlagStatus.defaultProps = {
    flag: 'none',
    showInfo: true
};

function FlagStatus({ flag, showInfo }) {
    const infoMarkup = (
        <OverlayTrigger trigger="hover" placement="right-start" overlay={<FlagPopover />}>
            <div className="info">
                <h6 className="title">i</h6>
            </div>
        </OverlayTrigger>
    );

    return (
        <>
            <span className="title flag-status">FLAG STATUS</span>
            {showInfo && infoMarkup}
            {flag && <Flag color={flag} />}
        </>
    );
}

export default FlagStatus;
